<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
	<div style="height: 100%; overflow: hidden">
		<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
			<a-col :span="24" class="mb-24">
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }">
					<template #title>
						<a-row type="flex" align="middle" justify="space-between">
							<a-col :span="24" :md="4">
								<!-- 公告： -->
								<h5 class="font-semibold m-0" v-show="config.notice_status == 1">
									<a :href="config.notice_url" target="_blank"
										><img
											v-show="config.notice_status == 1"
											src="../assets/imgs/horn.png"
											width="30"
											height="30"
											alt="" />
										<span style="color: #dab2f9">{{ config.notice }}</span>
									</a>
								</h5>
							</a-col>
							<a-col :span="24" :md="20" style="display: flex; align-items: center; justify-content: flex-end">
								<a-radio-group v-model="classifySelected" size="small" @change="getSecondClassify">
									<a-radio-button :value="item.id" v-for="(item, index) in classifyData" :key="'classify_' + item.id"
										>{{ item.title }}（{{ item.count }}）
										<!-- <template slot="row">
											{{ row.count }}
										</template> -->
									</a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
					</template>

					<div v-for="(item, index) in classifyData" :key="'first_' + item.id">
						<!-- <a-row :gutter="[24, 24]" v-if="item.id == classifySelected">
							:span="24" :sm="12" :md="8" :lg="8" :xl="6"
							<a-col :span="24" v-for="(item1, index1) in item.children" :key="'second_' + item1.id" @click="classifyItem(item1.id)">
								<div class="footer_box">
									<span class="icon">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827" />
											<path d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827" />
											<path d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827" />
										</svg>
										<img :src="item1.image" alt="" />
									</span>
									<h6>{{ item1.title }}</h6>
									<p class="c-line-2">{{ item1.desc }}</p>
									<div class="collect" v-if="token" @click.stop="collectWriteItem(item.id, item1.id, item1.isVote)">
										<a-icon type="star" theme="filled" :style="{ fontSize: '18px', color: item1.isVote == 0 ? '#333' : '#1890ff', display: 'block' }" />
									</div>
								</div>
							</a-col>
						</a-row> -->
						<div
							id="CharacterGrid"
							style="
								margin-top: 8px;
								display: grid;
								padding-right: 24px;
								gap: 4px;
								grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
							"
							v-if="item.id == classifySelected">
							<div
								class="character-slide-card-v3"
								v-for="(item1, index1) in item.children"
								:key="'second_' + item1.id"
								@click="classifyItem(item1.id)">
								<div style="width: 200px; height: 300px"></div>
								<div class="chartacter-item" style="width: 180px; height: 206px; padding: 0px 12px; cursor: pointer">
									<div style="display: flex; flex-direction: column">
										<!-- background-image: url(item1.image);align-items: center; -->
										<div
											style="
												width: 108px;
												margin: auto;
												height: 108px;
												border-radius: 14px;
												aspect-ratio: 1 / 1;
												background-size: cover;
												margin-bottom: 8px;
												filter: saturate(1);
												font-size: 2em;
												justify-content: center;
												align-items: center;
												text-align: center;
												display: flex;
												position: relative;
											">
											<img width="100%" height="100%" style="border-radius: 14px" :src="item1.image" alt="" />
											<div
												v-show="item1.reseller"
												style="
													width: 25px;
													height: 25px;
													position: absolute;
													top: 83px;
													right: 0;
													background-color: #1890ff;
													text-align: center;
													line-height: 20px;
													border-radius: 15px 0px 13px 0px;
												">
												<img
													style="width: 20px; height: 20px; margin-top: 5px; border-radius: 10px"
													src="../assets/imgs/renzheng2.png"
													alt="" />
											</div>
										</div>
										<div style="display: flex; position: absolute; right: 5px">
											<div
												class="collect"
												v-if="token"
												@click.stop="collectWriteItem(item.id, item1.id, item1.isVote)"
												style="font-size: 12px; cursor: pointer">
												<a-icon
													type="star"
													theme="filled"
													:style="{
														fontSize: '18px',
														color: item1.isVote == 0 ? '#333' : '#dab2f9',
														display: 'block',
													}" />
											</div>
										</div>
										<div
											style="
												font-size: 14px;
												font-weight: bold;
												margin-top: 15px;
												margin-bottom: 8px;
												overflow: hidden;
												width: 95%;
												text-overflow: ellipsis;
												display: block;
												text-align: center;
												-webkit-line-clamp: 1;
												white-space: nowrap;
											">
											{{ item1.title }}
											<img
												v-if="item1.sex == '女'"
												width="15"
												height="15"
												style="margin-bottom: 2px"
												src="../assets/imgs/woman.png"
												alt="" />
											<img
												v-else
												width="15"
												height="15"
												style="margin-bottom: 2px"
												src="../assets/imgs/man.png"
												alt="" />
										</div>
										<div
											style="
												font-size: 12px;
												text-align: left;
												max-width: 100%;
												display: -webkit-box;
												-webkit-box-orient: vertical;
												-webkit-line-clamp: 1;
												overflow: hidden;
												text-overflow: ellipsis;
											">
											{{ item1.welcome }}
										</div>
									</div>
								</div>
								<div style="display: flex; justify-content: space-between; align-items: center; width: 105%">
									<div
										class="username-truncated"
										style="font-size: 12px; width: 85px; display: block; overflow: hidden; text-overflow: ellipsis">
										<div style="color: inherit; text-decoration: none; --darkreader-inline-color: inherit">
											@ {{ item1.user_name }}
										</div>
									</div>
									<div style="display: flex; flex-direction: row; align-items: center">
										<div class="collect" style="font-size: 12px; cursor: pointer">
											<!-- @click.stop="collectWriteItem(item.id, item1.id, item1.isVote)" v-if="token"-->
											<!-- <a-icon type="star" theme="filled"
												:style="{ fontSize: '18px', color: item1.isVote == 0 ? '#333' : '#1890ff', display: 'block' }" /> -->
											<img width="12" height="12" src="../assets/imgs/对话svg图标.svg" alt="" />
											{{ item1.usages }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a-card>
			</a-col>
			<a-col>
				<a-layout-footer class="mb-5">
					<a-row type="flex">
						<a-col :span="24" :md="24">
							<div
								class="copyright font-regular text-sm"
								style="display: flex; justify-content: start; align-items: center">
								<span>友情链接：</span>
								<p class="copyright font-regular text-sm" v-for="(item, index) in link" :key="index">
									<a :href="item.url" style="color: #8c8c8c; font-weight: normal; margin-left: 5px" target="_black">
										<span class="link">{{ item.title }}</span></a
									>
								</p>
							</div>
							<br />
							<p class="copyright font-regular text-sm">
								<!-- <span class="mr-10">Copyright © 2023 WangHongXiu.com 金鲨信息科技重庆有限公司（wow）版权所有</span> -->
								<!-- <br /> -->
								<span class="mr-10" v-for="(item, index) in config.copyrights" :key="'copyright_' + index">
									<img :src="item.image ? item.image : ''" width="15" height="15" alt="" />
									<a style="color: #8c8c8c; font-weight: normal" :href="item.url" target="_blank">
										<span :class="item.url == '' ? '' : 'link'">{{ item.name }}</span>
									</a>
								</span>
							</p>
						</a-col>
					</a-row>
				</a-layout-footer>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	export default {
		components: {},
		data() {
			return {
				classifyData: [],
				classifySelected: "",
				collectNum: "",
				link: [],
			};
		},
		computed: {
			...mapGetters("user", ["token"]),
			...mapGetters("app", ["config"]),
		},
		created() {
			this.getFirstClassify();
			this.getCollectClassify();

			// if(!token){

			// }

			this.getlink();
		},
		mounted() {},
		methods: {
			objectArraySort(keyName) {
				return function (objectN, objectM) {
					var valueN = objectN[keyName];
					var valueM = objectM[keyName];
					if (valueN < valueM) return 1;
					else if (valueN > valueM) return -1;
					else return 0;
				};
			},
			// 友情链接 getlink
			getlink() {
				this.$http("common.getlink").then((res) => {
					if (res.code === 1) {
						console.log(res.data);
						this.link = res.data;
					}
				});
			},

			// 获取收藏分类
			getCollectClassify() {
				this.$http("role.collectList").then((res) => {
					if (res.code === 1) {
						// console.log('获取收藏分类', res.data);
						let arr = [];
						res.data.map((item) => {
							const {
								prompt: { id, title, desc, image, isVote, usages, user_name, sex, reseller, welcome },
							} = item;
							arr.push({
								id,
								title,
								desc,
								image,
								isVote,
								usages,
								user_name,
								sex,
								reseller,
								welcome,
							});
						});
						this.classifyData.map((item) => {
							if (item.id === "collect") {
								item.children = arr;
								item.count = item.children.length;
							}
						});
						// console.log('获取收藏分类', this.classifyData);
					}
				});
			},
			// 收藏
			collectWriteItem(pid, cid, vote) {
				this.$http("role.collect", { prompt_id: cid }).then((res) => {
					if (res.code === 1) {
						this.classifyData.map((item) => {
							if (item.id == pid) {
								item.children.map((item1) => {
									if (item1.id == cid) {
										item1.isVote = vote == 0 ? 1 : 0;
									}
								});
							}
						});
						this.$message.success(res.msg);
						this.getCollectClassify();
					}
				});
			},
			// 进入当前分类
			classifyItem(id) {
				console.log(id, "id");
				this.$router.push("/role/chat?id=" + id);
			},
			// 获取一级分类
			getFirstClassify() {
				this.$http("role.first").then((res) => {
					//&& res.data.length
					if (res.code === 1) {
						let arr = [];
						res.data.type.map((item, index) => {
							arr.push({
								id: item.id,
								title: item.title,
								children: [],
								count: item.count,
								usages: item.usages,
								user_name: item.user_name,
								reseller: item.reseller,
								sex: item.sex,
								welcome: item.welcome,
							});
						});
						this.classifyData = arr;
						this.classifyData.unshift({
							id: "0",
							title: "全部",
							children: [],
							count: res.data.AllNumber,
						});
						this.classifySelected = arr[0].id;
						this.getSecondClassify();
						if (this.token) {
							this.classifyData.unshift({
								id: "collect",
								title: "收藏",
								children: [],
								count: 0,
							});
							this.getCollectClassify();
						}
						// console.log(11111, this.classifyData);
					}
				});
			},
			// 获取二级分类
			getSecondClassify() {
				if (this.classifySelected === "collect" && this.token) {
					this.getCollectClassify();
					return;
				}
				this.$http("role.second", { topic_id: this.classifySelected }).then((res) => {
					if (res.code === 1) {
						res.data = res.data.sort(function (a, b) {
							return b.usages - a.usages;
						});
						this.classifyData.map((item, index) => {
							if (item.id == this.classifySelected) {
								item.children = res.data.map(
									({ id, title, desc, image, isVote, usages, user_name, sex, reseller, welcome }) => ({
										id,
										title,
										desc,
										image,
										isVote,
										usages,
										user_name,
										sex,
										reseller,
										welcome,
									}),
								);
							}
						});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.footer_box {
		width: 400px;
		height: 400px;
		padding: 12px;
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		position: relative;
		border: 1px solid transparent;
		margin-right: 10px;

		&:hover {
			border-color: #1890ff;
			box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
		}

		.icon {
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
			overflow: hidden;

			svg path {
				fill: #1890ff;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		h6 {
			color: #000;
		}

		p {
			margin-bottom: 0;
			line-height: 20px;
			height: 40px;
		}

		.collect {
			position: absolute;
			// top: 18px;
			right: 12px;
			bottom: 10px;
			cursor: pointer;
		}
	}

	.character-slide-card-v3 {
		flex: 1 1 0%;
		width: 100%;
		height: 240px;
		background: rgb(243, 240, 240);
		display: flex;
		border-radius: 12px;
		justify-content: space-between;
		align-items: center;
		padding: 12px;
		flex-direction: column;
		--darkreader-inline-bgimage: initial;
		--darkreader-inline-bgcolor: #2b2c2d;
		// #2b2c2d rgb(43, 44, 45)
	}

	.character-slide-card-v3:hover {
		background-color: #dddbdb;
	}

	.chartacter-item {
		position: relative;
	}

	.link:hover {
		color: #1890ff;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
		border-color: rgb(218, 178, 249) !important;
		color: rgb(218, 178, 249) !important;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		border-color: rgb(218, 178, 249) !important;
		color: rgb(218, 178, 249) !important;
		box-shadow: 0 0 0 1px rgb(218, 178, 249) !important;
		z-index: 1000;
	}
	.ant-radio-button-wrapper:hover {
		border-color: rgb(218, 178, 249) !important;
		color: rgb(218, 178, 249) !important;
	}
	.ant-radio-button-wrapper:not(:first-child)::before {
		display: none !important;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		border-color: transparent !important;
	}
</style>
